import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"16\" cy=\"16\" r=\"16\" fill=\"#F7F7F7\"></circle><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.5 8.476C8.83675 8.476 8.2008 8.73981 7.73201 9.20921C7.26324 9.67858 7 10.315 7 10.9786V20.9734C7 21.637 7.26324 22.2734 7.73201 22.7428C8.2008 23.2122 8.83675 23.476 9.5 23.476H15.6353C15.4109 23.1638 15.2159 22.8291 15.0541 22.476H9.5C9.10238 22.476 8.72092 22.3178 8.43957 22.0361C8.15819 21.7544 8 21.3721 8 20.9734V10.9786C8 10.5798 8.15819 10.1976 8.43957 9.91586C8.72092 9.63415 9.10238 9.476 9.5 9.476H21.5C21.8976 9.476 22.2791 9.63415 22.5604 9.91586C22.8418 10.1976 23 10.5798 23 10.9786V14.53C23.3531 14.6918 23.6878 14.8869 24 15.1112V10.9786C24 10.315 23.7368 9.67858 23.268 9.20921C22.7992 8.73981 22.1632 8.476 21.5 8.476H9.5Z\" fill=\"#404040\"></path><circle cx=\"20.5056\" cy=\"19.9821\" r=\"5.49391\" stroke=\"#404040\"></circle><path d=\"M20.5 17.9211V19.976H22.6146\" stroke=\"#404040\"></path><path d=\"M20.5059 22.0309L20.5059 19.976L18.3912 19.976\" stroke=\"#404040\"></path><path d=\"M19.4062 10.9166L19.4062 7.00001\" stroke=\"#404040\" stroke-linecap=\"round\"></path><path d=\"M11.4941 10.9166L11.4941 7.00001\" stroke=\"#404040\" stroke-linecap=\"round\"></path>", 7)
  ])))
}
export default { render: render }