import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#clip0_3598_4082)\"><path d=\"M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z\" fill=\"#F7F7F7\"></path><path d=\"M16.0808 10.2617C16.0808 10.2617 16.0856 16.7132 11.7953 16.7189C7.15976 16.7246 7.61795 10.211 7.61795 10.211\" stroke=\"#404040\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M16.0801 10.1953C15.5278 10.1953 15.0801 9.74757 15.0801 9.19528C15.0801 8.643 15.5278 8.19528 16.0801 8.19528C16.6323 8.19528 17.0801 8.643 17.0801 9.19528C17.0801 9.74757 16.6323 10.1953 16.0801 10.1953Z\" stroke=\"#404040\" stroke-miterlimit=\"10\"></path><path d=\"M7.60547 10.211C7.05319 10.211 6.60547 9.76328 6.60547 9.211C6.60547 8.65871 7.05319 8.211 7.60547 8.211C8.15776 8.211 8.60547 8.65871 8.60547 9.211C8.60547 9.76328 8.15776 10.211 7.60547 10.211Z\" stroke=\"#404040\" stroke-miterlimit=\"10\"></path><path d=\"M24.055 20.8816C24.0455 19.8739 23.2203 19.0649 22.2134 19.0744C21.2065 19.0839 20.3968 19.9084 20.4063 20.916C20.4158 21.9237 21.241 22.7327 22.2479 22.7232C23.2548 22.7137 24.0646 21.8892 24.055 20.8816Z\" stroke=\"#404040\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M11.9316 16.7715C12.2745 18.8287 12.8296 21.1762 13.8745 22.6002C15.9029 25.3644 19.9317 24.4288 21.4175 22.6002\" stroke=\"#404040\" stroke-linecap=\"round\"></path></g><defs><clipPath id=\"clip0_3598_4082\"><rect width=\"32\" height=\"32\" fill=\"white\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }